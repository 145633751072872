import React from "react"

import logo from "../images/logo.png"
import SectionHeader from "./section-header"
import {appTitle} from "./constants"
import {appPromo} from "./constants"

const Content = () => (
  <div style={{ padding: "1rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title={appTitle}
    />
    <content
                       className="multiGrid"
    >
                       <div>
                       <img src={logo} alt="app logo" className="logoImage" />
                       </div>
      {appPromo}

    </content>
  </div>
)

export default Content
