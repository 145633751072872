import React from "react"

export const appStoreUrl = `https://apps.apple.com/app/id1480728049`
export const appTitle = `Bzz ~ Vibrating Body Massager`
export const appSubtitle = 'Strong! Stress Relief Therapy'
export const appPromo = <div style={{ textAlign: "left" }}>
Turn your phone into a vibrating massager!<br />
• Heavy duty, vibrates as strong as your phone can muster<br />
• Customize strength and pattern. For free!<br />
• Automatic screen lock<br />
• Easy to use<br />
• No interruptions, no subscriptions!<br />
<br />
Fully customizable, choose from haptic and vibration patterns.<br />
Download and start using, every pattern and feature is free!<br />
<br />
Troubleshoot problems with vibration. Turn on vibration in settings:<br />
1. Settings -> General -> Accesibility -> Vibration<br />
2. Settinga -> Sounds &amp; Haptics -> Vibrate on Ring, Vibrate on Silent<br />
<br />
• Soothe cramps<br />
• Alleviate tension<br />
• Relax muscle knots<br />
• Scalp massage<br />
• Anything you would use a portable massager for! Why spend more money to buy one, when you already have your phone?<br />
</div>